import { UnpaidInvoice } from 'src/types/invoice/UnpaidInvoice';

export const groupByPaymentDueDate = (invoices: UnpaidInvoice[]): Map<string, UnpaidInvoice[]> => {
  return invoices.reduce((map, invoice) => {
    const grouped = map.get(`${invoice.paymentDueDate}`);

    if (grouped) {
      grouped.push(invoice);
      map.set(`${invoice.paymentDueDate}`, grouped);
    } else {
      map.set(`${invoice.paymentDueDate}`, [invoice]);
    }
    return map;
  }, new Map<string, UnpaidInvoice[]>());
};

export const calculateTotalAmount = (invoices: UnpaidInvoice[]) => {
  return invoices.reduce((total, invoice) => total + invoice.totalAmount, 0);
};

import { ListGroup, DrawerNavigation } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { Invoice } from 'src/types/invoice/Invoice';
import { PaymentTransaction } from 'src/types/invoice/PaymentTransaction';
import './about-tab.scss';

interface AboutTabProps {
  invoice: Invoice;
  paymentTransactions: PaymentTransaction[];
  onUpdateInvoice: () => void;
}

const AboutTab: FC<AboutTabProps> = ({ invoice, paymentTransactions, onUpdateInvoice }) => {
  const { t } = useTranslation();

  const isUpdateButtonDisabled =
    invoice.invoiceStatus === 'INVOICE_PAYMENT_REQUEST_SENT' ||
    invoice.invoiceStatus === 'INVOICE_PAID' ||
    (paymentTransactions.length > 0 && invoice.remainingAmount !== 0);

  return (
    <div className="about-tab">
      <ListGroup variant="drawer-navigation">
        <DrawerNavigation
          label={t('invoice:invoice-drawer.about-tab.update-invoice-button-label')}
          onClick={onUpdateInvoice}
          disabled={isUpdateButtonDisabled}
          data-cy="payment-transactions-tab__create-transaction"
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('invoice:invoice-drawer.about-tab.invoice-type-code-label')}
          value={t(`invoice:invoice-type-code.${invoice.invoiceTypeCode}`)}
        />
      </ListGroup>
    </div>
  );
};

export default AboutTab;

import { gql } from '@apollo/client';

export const SEARCH_INVOICES = gql`
  query SearchInvoices($request: CommonSearchInput!) {
    searchInvoices(request: $request) {
      paging {
        hasNext
        totalNumberOfElements
      }
      searchInvoices {
        id
        errandId
        employer {
          id
          legalName
        }
        invoiceStatus
        paymentDueDate
        dueDate
        invoiceTypeCode
        insuranceProvider {
          id
          legalName
          organisationNumber
        }
        totalAmount
        invoiceNumber
        agreementNumber
        note
        accountNumberTo
        ocrNumber
        remainingAmount
        account {
          paymentServiceProvider
          bank
          accountNumber
        }
      }
    }
  }
`;

import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { FC } from 'react';
import DrawerBase from 'src/components/Common/DrawerBase';
import { Invoice } from 'src/types/invoice/Invoice';
import { PaymentDueDate } from 'src/types/invoice/PaymentDueDate';
import { PaymentTransaction } from 'src/types/invoice/PaymentTransaction';
import { Payment } from 'src/types/payment/Payment';
import { InvoiceDrawerTab } from 'src/types/tabs/InvoiceDrawerTab';
import AboutTab from './AboutTab';
import PaymentDueDateTab from './PaymentDueDateTab';
import PaymentTransactionsTab from './PaymentTransactionsTab';

interface InvoiceDrawerOverviewProps {
  invoice: Invoice;
  paymentDueDates: PaymentDueDate[];
  paymentTransactions: PaymentTransaction[];
  payments: Payment[];
  onClose: () => void;
  onUpdateInvoice: () => void;
  onAddNewPaymentDueDate: () => void;
  onSelectPaymentDueDate: (id: string) => void;
  onCreateNewTransaction: () => void;
  onSelectTransaction: (id: string) => void;
  onSelectedPayment: (id: string) => void;
  currentTab: InvoiceDrawerTab;
}

const InvoiceDrawerOverview: FC<InvoiceDrawerOverviewProps> = ({
  invoice,
  paymentDueDates,
  paymentTransactions,
  payments,
  onClose,
  onUpdateInvoice,
  onAddNewPaymentDueDate,
  onSelectPaymentDueDate,
  onCreateNewTransaction,
  onSelectTransaction,
  onSelectedPayment,
  currentTab,
}) => {
  return (
    <DrawerBase
      onClose={onClose}
      title={t('invoice:invoice-drawer.drawer-title') + ` ${invoice?.invoiceNumber}`}
      subTitle={
        <div className="invoice-drawer__sub-title">
          <Typography>{invoice?.employer?.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      }
    >
      <Tabs tabIndex={currentTab}>
        <Tab title={t('invoice:invoice-drawer.about-tab.tab-label')}>
          <AboutTab invoice={invoice} paymentTransactions={paymentTransactions} onUpdateInvoice={onUpdateInvoice} />
        </Tab>
        <Tab title={t('invoice:invoice-drawer.payment-due-dates-tab.tab-label')}>
          <PaymentDueDateTab
            invoice={invoice}
            paymentDueDates={paymentDueDates}
            onAddNewPaymentDueDate={onAddNewPaymentDueDate}
            onSelectPaymentDueDate={onSelectPaymentDueDate}
          />
        </Tab>
        <Tab title={t('invoice:invoice-drawer.transactions-tab.tab-label')}>
          <PaymentTransactionsTab
            paymentTransactions={paymentTransactions}
            payments={payments}
            onCreateNewTransaction={onCreateNewTransaction}
            onSelectTransaction={onSelectTransaction}
            onSelectPayment={onSelectedPayment}
          />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default InvoiceDrawerOverview;

import React from 'react';
import { TableRow, Th } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';

const InsuranceEventsTableHeads = () => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      title: t('reporting:insurance-events.event'),
      fieldName: t('reporting:insurance-events.event'),
    },
    {
      title: t('reporting:insurance-events.employment-number-abbr'),
      fieldName: t('reporting:insurance-events.employment-number-abbr'),
    },
    {
      title: t('reporting:employment-group'),
      fieldName: t('reporting:employment-group'),
    },
    {
      title: t('reporting:insurance-events.event-from-date'),
      fieldName: t('reporting:insurance-events.event-from-date'),
    },
    {
      title: t('reporting:insurance-events.benefit'),
      fieldName: t('reporting:insurance-events.benefit'),
    },
    {
      title: t('reporting:insurance-events.recipient'),
      fieldName: t('reporting:insurance-events.recipient'),
    },
    {
      title: t('reporting:insurance-events.value'),
      fieldName: t('reporting:insurance-events.value'),
    },
    {
      title: t('reporting:insurance-events.status'),
      fieldName: t('reporting:insurance-events.status'),
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head, index) => {
        return (
          <Th compressed key={`insurance-events-table-head-${index}`}>
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};
export default InsuranceEventsTableHeads;

import { Button, TableRow, Td, ToastContext } from 'fgirot-k2-ui-components';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useEmployerLegalName } from 'src/hooks/useEmployerLegalName';
import { DocumentReference } from 'src/types/documents/DocumentReference';
import { stringOrBlank } from 'src/util/StringUtil';
import { formatDateTimeString } from 'src/util/date/PeriodUtil';
import { GET_DOWNLOAD_DOCUMENT } from './DownloadDocumentHook';
import { useLazyQuery } from '@apollo/client';

interface DocumentArchiveTableRowProps {
  documentReference: DocumentReference;
}

const DocumentArchiveTableRow: FC<DocumentArchiveTableRowProps> = ({ documentReference: documentReference }) => {
  const { t } = useTranslation();

  const [execQuery, { loading, error }] = useLazyQuery(GET_DOWNLOAD_DOCUMENT);

  const { displayToast } = useContext(ToastContext);

  const handleDownload = async (id: string) => {
    const downloadDocumentResponse = await execQuery({
      variables: { documentReferenceId: id },
      context: { employerId: documentReference.employerId },
    });

    if (!downloadDocumentResponse || downloadDocumentResponse.error) {
      displayToast('error', t('documents:document-archive-tab.error.could-not-download-file'), '');
      return;
    } else {
      const documentData = downloadDocumentResponse.data.downloadDocument;
      const blob = base64ToBlob(documentData.base64EncodedDocument, 'application/octet-stream');
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = documentData.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const base64ToBlob = (base64, contentType) => {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: contentType });
  };

  return (
    <TableRow data-testid={`document-reference-table-row__${documentReference.id}`}>
      <Td>{stringOrBlank(documentReference.fileName)}</Td>
      <Td>{stringOrBlank(t(`documents:format-types.${documentReference.contentType}`))}</Td>
      <Td>{stringOrBlank(documentReference.fromPeriod)}</Td>
      <Td>{stringOrBlank(documentReference.toPeriod)}</Td>
      <Td>{stringOrBlank(useEmployerLegalName(documentReference.employerId))}</Td>
      <Td>{formatDateTimeString(documentReference.createdDate)}</Td>
      <Td>{stringOrBlank(documentReference.latestDownloadDate)}</Td>
      <Td>
        <Button
          label={t('documents:document-archive-tab.table.download-button')}
          onClick={() => handleDownload(documentReference.id)}
          disabled={loading || !!error}
        />
      </Td>
    </TableRow>
  );
};

export default DocumentArchiveTableRow;

import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { useEventsFilterOptions } from './useEventsFilterOptions';
import { useState } from 'react';
import './events-filter-modal.scss';
import { Employer } from 'src/types/employer/Employer';
import { SearchEventsFilters } from 'src/types/reporting/SearchEventsRequest';

interface EventsFilterModalProps {
  open: boolean;
  onClose: () => void;
  filters: SearchEventsFilters;
  period: string;
  employers: Employer[];
  applyFilters: (filters: SearchEventsFilters) => void;
  isInsuranceEvents?: boolean;
}

const EventsFilterModal: React.FunctionComponent<EventsFilterModalProps> = ({
  open,
  onClose,
  filters,
  period,
  employers,
  applyFilters,
  isInsuranceEvents,
}) => {
  const { t } = useTranslation();
  const {
    employerOptions,
    insuranceProviderOptions,
    benefitTypeOptions,
    policyBenefitNameOptions,
    insuranceEventTypeOptions,
    insuranceEventStatusOptions,
    reportingTypeOptions,
  } = useEventsFilterOptions(period, employers, isInsuranceEvents);
  const [newFilters, setNewFilters] = useState<SearchEventsFilters>({ ...filters });

  const onApply = () => {
    applyFilters(newFilters);
    onClose();
  };

  const onEmployerFilterChange = (input: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      employerFilter: {
        employerIds: input.map((it) => it.value),
      },
    });
  };

  const onInsuranceProviderFilterChange = (input: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      insuranceProviderFilter: {
        insuranceProviderIds: input.map((it) => it.value),
      },
    });
  };

  const onBenefitTypeFilterChange = (input: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      benefitTypeFilter: {
        benefitTypes: input.map((it) => it.value),
      },
    });
  };

  const onPolicyBenefitFilterChange = (input: Option<string[]>[]) => {
    setNewFilters({
      ...newFilters,
      policyBenefitFilter: {
        policyBenefitIds: input.flatMap((it) => it.value),
      },
    });
  };

  const onInsuranceEventTypeFilterChange = (input: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      insuranceEventTypeFilter: {
        insuranceEventTypes: input.map((it) => it.value),
      },
    });
  };

  const onReportingTypeFilterChange = (input: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      reportingTypeFilter: {
        reportingTypes: input.map((it) => it.value),
      },
    });
  };

  const onInsuranceEventStatusFilterChange = (input: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      insuranceEventStatusFilter: {
        insuranceEventStatuses: input.map((it) => it.value),
      },
    });
  };

  const selectedEmployerOptions = employerOptions.filter((option) =>
    newFilters.employerFilter?.employerIds?.includes(option.value),
  );

  const selectedInsuranceProviderOptions = insuranceProviderOptions.filter((option) =>
    newFilters.insuranceProviderFilter?.insuranceProviderIds?.includes(option.value),
  );

  const selectedBenefitTypeOptions = benefitTypeOptions.filter((option) =>
    newFilters.benefitTypeFilter?.benefitTypes?.includes(option.value),
  );

  const selectedPolicyBenefitNameOptions = policyBenefitNameOptions.filter((option) =>
    newFilters.policyBenefitFilter?.policyBenefitIds.some((id) => option.value.includes(id)),
  );

  const selectedInsuranceEventTypeOptions = insuranceEventTypeOptions.filter((option) =>
    newFilters.insuranceEventTypeFilter?.insuranceEventTypes?.includes(option.value),
  );

  const selectedReportingTypeOptions = reportingTypeOptions.filter((option) =>
    newFilters.reportingTypeFilter?.reportingTypes?.includes(option.value),
  );

  const selectedInsuranceEventStatusOptions = insuranceEventStatusOptions.filter((option) =>
    newFilters.insuranceEventStatusFilter?.insuranceEventStatuses?.includes(option.value),
  );

  const selectProps = {
    small: true,
    outlined: true,
    selectAll: true,
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="events-filter-modal"
        title={t('reporting:search-filters.filter')}
        icon={<Icon type="Sliders" className="events-filter-modal__icon" />}
        size="wide"
      >
        <div className="events-filter-modal__filters">
          <div className="events-filter-modal__select">
            <Select
              multiple
              label={t('reporting:search-filters:employer')}
              {...selectProps}
              selected={selectedEmployerOptions}
              onChange={onEmployerFilterChange}
              options={employerOptions}
              placeholder={t('reporting:search-filters.choose-employers')}
            />
          </div>
          <div className="events-filter-modal__select">
            <Select
              multiple
              label={t('reporting:search-filters.insurance-provider')}
              {...selectProps}
              selected={selectedInsuranceProviderOptions}
              onChange={onInsuranceProviderFilterChange}
              options={insuranceProviderOptions}
              placeholder={t('reporting:search-filters.choose-insurance-providers')}
            />
          </div>
          <div className="events-filter-modal__select">
            <Select
              multiple
              label={t('reporting:search-filters.benefit')}
              {...selectProps}
              selected={selectedPolicyBenefitNameOptions}
              onChange={onPolicyBenefitFilterChange}
              options={policyBenefitNameOptions}
              placeholder={t('reporting:search-filters.choose-benefit-names')}
            />
          </div>
          <div className="events-filter-modal__select">
            <Select
              multiple
              label={t('reporting:search-filters.benefit-type')}
              {...selectProps}
              selected={selectedBenefitTypeOptions}
              onChange={onBenefitTypeFilterChange}
              options={benefitTypeOptions}
              placeholder={t('reporting:search-filters.choose-benefit-types')}
            />
          </div>
          <div className="events-filter-modal__select">
            <Select
              label={t('reporting:search-filters.reporting-type')}
              small
              outlined
              multiple
              selectAll
              selected={selectedReportingTypeOptions}
              onChange={onReportingTypeFilterChange}
              options={reportingTypeOptions}
              placeholder={t('reporting:search-filters.choose-reporting-types')}
            />
          </div>
          <div className="events-filter-modal__select">
            <Select
              label={t('reporting:search-filters.insurance-event-status')}
              small
              outlined
              multiple
              selectAll
              selected={selectedInsuranceEventStatusOptions}
              onChange={onInsuranceEventStatusFilterChange}
              options={insuranceEventStatusOptions}
              placeholder={t('reporting:search-filters.choose-insurance-event-statuses')}
            />
          </div>
          <div className="events-filter-modal__select">
            <Select
              label={t('reporting:search-filters.insurance-event-type')}
              small
              outlined
              multiple
              selectAll
              selected={selectedInsuranceEventTypeOptions}
              onChange={onInsuranceEventTypeFilterChange}
              options={insuranceEventTypeOptions}
              placeholder={t('reporting:search-filters.choose-insurance-event-types')}
            />
          </div>
        </div>
        <CardAction>
          <CardAction>
            <Button label={t('common:cancel')} type="link" onClick={onClose} />
            <Button label={t('reporting:search-filters.use')} onClick={onApply} />
          </CardAction>
        </CardAction>
      </Card>
    </Modal>
  );
};

export default EventsFilterModal;

import React, { useState } from 'react';
import { SearchPremiumInvoiceRowsPage } from 'src/types/invoice/PremiumInvoiceRow';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { useParams } from 'react-router-dom';
import PremiumInvoiceRows from './PremiumInvoiceRows';
import { SEARCH_PREMIUM_INVOICE_ROWS } from 'src/graphql/schema/query/invoice/SearchPremiumInvoiceRows';
import { PremiumInvoiceRowsFilter } from 'src/types/invoice/PremiumInvoiceRowsFilter';
import { getInitialSearchPremiumInvoiceRowsRequest } from './PremiumInvoiceRows/utils/premiumInvoiceRowUtils';
import './premium-invoice-rows-overview.scss';
import { CommonSearchInput } from 'src/types/common/CommonSearchInput';
import { SortDirection } from 'fgirot-k2-ui-components';
import { PremiumInvoiceRowSortField } from './PremiumInvoiceRows/PremiumInvoiceRowsTable/PremiumInvoiceRowSortField';
import { Month } from 'src/types/Month';
import { convertMonthEnumToNumber } from 'src/util/date/Months';
import { formatDate } from 'src/util/date/PeriodUtil';
import { Sort } from 'src/types/common/Sort';

const PremiumInvoiceRowsOverview = () => {
  const [premiumInvoiceRowsFilter, setPremiumInvoiceRowsFilter] = useState<PremiumInvoiceRowsFilter>(
    getInitialSearchPremiumInvoiceRowsRequest(),
  );

  const [searchInput, setSearchInput] = useState<string>('');
  const [sort, setSort] = useState<Sort>({ property: 'periodStart', direction: 'DOWN' });
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);

  const { employeeId } = useParams();

  const handleTextSearch = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  const handleApplyFilters = (filters: PremiumInvoiceRowsFilter) => {
    setPremiumInvoiceRowsFilter(filters);
  };

  const handleSortingChange = (property: PremiumInvoiceRowSortField, sortDirection: SortDirection) => {
    setSort({ property: property, direction: sortDirection });
  };

  const getPeriod = (year: number, month: Month, startPeriod: boolean) => {
    if (year == null) {
      return '';
    }

    const monthNumber = convertMonthEnumToNumber(month != null ? month : startPeriod ? 'JANUARY' : 'DECEMBER');
    const date = new Date(year, monthNumber + 1, startPeriod ? 1 : 0);
    return formatDate(date);
  };

  const request: CommonSearchInput = {
    paging: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    sorting: sort && [{ property: sort.property.toString(), direction: sort.direction == 'UP' ? 'ASC' : 'DESC' }],
    searchInputs: [
      searchInput?.length > 0 && {
        properties: ['invoiceNumber', 'agreementNumber', 'insuranceNumber'],
        value: searchInput,
        exactMatch: false,
      },
    ].filter((search) => search && search.value != ''),
    filters: [
      {
        // Invoices which are not validated have not gone through properly and should not be taken into account here
        property: 'invoiceStatus',
        values: ['INVOICE_ARRIVED', 'REJECTED', 'HANDLED', 'CHECKPOINT_PENDING'],
        condition: 'NOT_IN',
      },
      {
        // Only interested by this employee and therefore this is a static filter
        property: 'employeeId',
        values: employeeId,
        condition: 'EQ',
      },
      {
        property: 'periodStart',
        values: getPeriod(
          premiumInvoiceRowsFilter.periodStart?.year,
          premiumInvoiceRowsFilter.periodStart?.month,
          true,
        ),
        condition: 'GE',
      },
      {
        property: 'periodEnd',
        values: getPeriod(premiumInvoiceRowsFilter.periodEnd?.year, premiumInvoiceRowsFilter.periodEnd?.month, false),
        condition: 'LE',
      },
      {
        property: 'insuranceProviderId',
        values: premiumInvoiceRowsFilter.insuranceProviderIds,
        condition: 'IN',
      },
    ].filter((filter) => filter.values && filter.values.length > 0),
  };

  return (
    <QueryWrapper
      query={SEARCH_PREMIUM_INVOICE_ROWS}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: request,
        },
      }}
    >
      {({ searchPremiumInvoiceRows, paging }: SearchPremiumInvoiceRowsPage) => {
        return (
          <PremiumInvoiceRows
            premiumInvoiceRows={searchPremiumInvoiceRows}
            paging={paging}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            searchInput={searchInput}
            filter={premiumInvoiceRowsFilter}
            sort={sort}
            onApplySorting={handleSortingChange}
            onTextSearch={handleTextSearch}
            onApplyFilters={handleApplyFilters}
            onPageChange={handlePageChange}
          />
        );
      }}
    </QueryWrapper>
  );
};

export default PremiumInvoiceRowsOverview;

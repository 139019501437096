import { Employer } from '../employer/Employer';
import { InsuranceProvider } from '../InsuranceProvider';
import { PageableResponse } from '../PageableResponse';
import { InvoiceRow } from './InvoiceRow';

export interface SearchInvoicesPage extends PageableResponse {
  searchInvoices: Invoice[];
}

export interface InvoicesPage extends PageableResponse {
  invoices: Invoice[];
}

export interface InvoicePage extends Invoice, PageableResponse {
  invoiceRows: InvoiceRow[];
}

export interface Invoice {
  id: string;
  errandId: string;
  createdAt: Date;
  updatedAt: Date;
  sequenceNumber: number;
  invoiceNumber: string;
  fileName: string;
  invoiceStatus: InvoiceStatus;
  invoiceTypeCode: InvoiceTypeCode;
  invoiceIssuedCreationDate: Date;
  arrivalDate: Date;
  convertedDate: Date;
  hasPaperInvoice: boolean;
  agreementNumber: string;
  note: string;
  invoicePeriod: InvoicePeriod;
  employer: Employer;
  insuranceProvider: InsuranceProvider;
  dueDate: Date;
  paymentDueDate: Date;
  paymentMethod: string;
  ocrNumber: string;
  accountNumberTo: string;
  currencyCode: string;
  totalAmount: number;
  roundingAmount: number;
  vatAmount: number;
  remainingAmount: number;
}

export type InvoiceStatus =
  | 'CHECKPOINT_PENDING'
  | 'NO_PAYMENT_SERVICE'
  | 'CREDIT_OPEN'
  | 'ACCOUNTING_ONLY'
  | 'CREDIT_CLOSED'
  | 'PREPARED_FOR_PAYMENTS'
  | 'INVOICE_PAYMENT_FAILED'
  | 'INVOICE_PAYMENT_REQUEST_SENT'
  | 'INVOICE_PAID'
  | 'PAUSED';

export const invoiceStatuses: InvoiceStatus[] = [
  'CHECKPOINT_PENDING',
  'NO_PAYMENT_SERVICE',
  'CREDIT_OPEN',
  'ACCOUNTING_ONLY',
  'CREDIT_CLOSED',
  'PREPARED_FOR_PAYMENTS',
  'INVOICE_PAYMENT_REQUEST_SENT',
  'INVOICE_PAYMENT_FAILED',
  'INVOICE_PAID',
  'PAUSED',
];

export type InvoiceTypeCode = 'DEBIT' | 'CREDIT' | 'BOOKKEEPING';

export interface InvoicePeriod {
  periodStart: Date;
  periodEnd: Date;
}

import { SearchEventsRequest } from 'src/types/reporting/SearchEventsRequest';

export const getInitialSearchEventsRequest = (period: string, searchInput): SearchEventsRequest => {
  return {
    searchInput,
    wageFilePeriod: period,
    pageNumber: 0,
    pageSize: undefined,
    filters: {
      employerFilter: {
        employerIds: [],
      },
      insuranceProviderFilter: {
        insuranceProviderIds: [],
      },
      benefitTypeFilter: {
        benefitTypes: [],
      },
      policyBenefitFilter: {
        policyBenefitIds: [],
      },
      insuranceEventTypeFilter: {
        insuranceEventTypes: [],
      },
      reportingTypeFilter: {
        reportingTypes: [],
      },
      insuranceEventStatusFilter: {
        insuranceEventStatuses: [],
      },
    },
  };
};

import { DrawerNavigation, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/invoice/Invoice';
import { PaymentDueDate } from 'src/types/invoice/PaymentDueDate';
import { stringOrBlank } from 'src/util/StringUtil';
import './payment-due-date-tab.scss';

interface PaymentDueDateTabProps {
  invoice: Invoice;
  paymentDueDates: PaymentDueDate[];
  onAddNewPaymentDueDate: () => void;
  onSelectPaymentDueDate: (id: string) => void;
}

const PaymentDueDateTab: FC<PaymentDueDateTabProps> = ({
  invoice,
  paymentDueDates,
  onAddNewPaymentDueDate,
  onSelectPaymentDueDate,
}) => {
  const { t } = useTranslation();
  const isAddNewPaymentDueDateButtonDisabled =
    invoice.invoiceStatus === 'INVOICE_PAID' ||
    invoice.invoiceStatus === 'INVOICE_PAYMENT_REQUEST_SENT' ||
    invoice.invoiceTypeCode === 'BOOKKEEPING';

  return (
    <div className="payment-due-date-tab">
      <ListGroup variant="drawer-navigation">
        <DrawerNavigation
          label={t('invoice:invoice-drawer.payment-due-dates-tab.add-payment-due-date-button-label')}
          onClick={onAddNewPaymentDueDate}
          disabled={isAddNewPaymentDueDateButtonDisabled}
          data-cy="payment-due-date-tab__add-due-date"
        />
      </ListGroup>
      <ListGroup variant="drawer-navigation">
        {paymentDueDates.map((paymentDueDate) => (
          <DrawerNavigation
            label={
              <div className="payment-due-date-tab__drawer-navigation-label">
                <Typography bold variant="body2" component="span">
                  {t('invoice:invoice-drawer.payment-due-dates-tab.due-date-label') +
                    ` ${stringOrBlank(paymentDueDate.dueDate).substring(0, 10)}`}
                </Typography>
                {paymentDueDate.active && (
                  <Pill type="success" label={t('invoice:invoice-drawer.payment-due-dates-tab.active-due-date')} />
                )}
              </div>
            }
            value={stringOrBlank(paymentDueDate.note)}
            onClick={() => onSelectPaymentDueDate(paymentDueDate.id)}
            key={`PaymentDueDateTab-${paymentDueDate.id}`}
            data-cy="payment-due-date-tab__due-date-details"
          />
        ))}
      </ListGroup>
    </div>
  );
};

export default PaymentDueDateTab;

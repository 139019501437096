import { PolicyBenefitName } from 'src/types/policy/PolicyBenefit';

export const getGroupedBenefitIdsByName = (policyBenefitNames: PolicyBenefitName[]): Map<string, string[]> => {
  return policyBenefitNames?.reduce((acc, benefit) => {
    if (!acc.has(benefit?.name)) {
      acc.set(benefit?.name, []);
    }
    acc.get(benefit?.name)?.push(benefit?.id);
    return acc;
  }, new Map<string, string[]>());
};

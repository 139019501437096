import { Accordion, ListItem, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ModuleCardBox from 'src/components/Common/ModuleCardBox';
import { UnpaidInvoice } from 'src/types/invoice/UnpaidInvoice';
import { convertMonthNumberStringToEnum } from 'src/util/date/Months';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import UnpaidInvoiceDetails from './UnpaidInvoiceDetails';
import './unpaid-invoices-list-item.scss';
import { calculateTotalAmount } from 'src/components/Economy/utils';

interface UnpaidInvoicesListItemProps {
  groupedInvoicesByPaymentDueDate: UnpaidInvoice[];
  paymentDueDate: string;
}

const UnpaidInvoicesListItem: FC<UnpaidInvoicesListItemProps> = ({
  groupedInvoicesByPaymentDueDate,
  paymentDueDate,
}) => {
  const { t } = useTranslation();
  const totalAmount = calculateTotalAmount(groupedInvoicesByPaymentDueDate);

  const formattedDate =
    `${paymentDueDate.substring(8, 10)} ` +
    t(`common:months.${convertMonthNumberStringToEnum(paymentDueDate.substring(5, 7))}`) +
    ` ${paymentDueDate.substring(0, 4)}`;

  const header = (
    <div className="unpaid-invoices-list-item__header">
      {/* WCAG: Semantisk rubrik med aria-attribut */}
      <h3 className="unpaid-invoices-list-item__date">
        <ModuleCardBox
          data-testid="unpaid-invoices-list-item__date"
          className="unpaid-invoices-list-item__date"
          value={formattedDate}
        />
      </h3>
      <Typography bold variant="body2" aria-label={t('economy:economy-overview-tab.total-amounts')}>
        {t('economy:economy-overview-tab.total-amounts') + formatSweAmountTextWithSuffixNoDecimal(totalAmount)}
      </Typography>
    </div>
  );

  return (
    <div className="unpaid-invoices-list-item" style={{ userSelect: 'text' }}>
      <ListItem
        compressed
        border
        data-cy="unpaid-invoices-list-item"
        header={header}
        aria-labelledby="unpaid-invoices-list-item__header"
      >
        <Accordion
          header={
            // WCAG: Semantisk rubrik med nivå
            <h4>
              <Typography variant="body2" aria-label={t('economy:economy-overview-tab.accordion-title')}>
                {t('economy:economy-overview-tab.accordion-title')}
              </Typography>
            </h4>
          }
        >
          <UnpaidInvoiceDetails groupedInvoicesByPaymentDueDate={groupedInvoicesByPaymentDueDate} />
        </Accordion>
      </ListItem>
    </div>
  );
};

export default UnpaidInvoicesListItem;

import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { invoiceStatuses, SearchInvoicesPage } from 'src/types/invoice/Invoice';
import LoadingPage from 'src/components/Common/LoadingPage';
import { SEARCH_INVOICES } from 'src/graphql/schema/query/invoice/SearchInvoices';
import { SearchInvoicesFilter } from 'src/types/invoice/SearchInvoicesFilter';
import Invoices from './Invoices/Invoices';
import { CommonSearchInput } from 'src/types/common/CommonSearchInput';
import { SortDirection } from 'fgirot-k2-ui-components';
import { InvoiceTableSortField } from './InvoicesTable/InvoiceTableSortField';
import { getDefaultFilters } from './utils/InvoiceUtils';

const InvoicesTab = () => {
  const [invoicesFilter, setInvoicesFilter] = useState<SearchInvoicesFilter>(getDefaultFilters());
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchAmountInput, setSearchAmountInput] = useState<string>('');
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const [sort, setSort] = useState<{ property?: InvoiceTableSortField; direction: SortDirection }>({
    property: 'paymentDueDate',
    direction: 'DOWN',
  });
  const handlePageChange = (page: number) => setPageNumber(page - 1);

  const handleTextSearch = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  const handleSearchAmount = (searchAmount: string) => {
    if (!isNaN(Number(searchAmount))) {
      setSearchAmountInput(searchAmount);
    }
  };

  const handleApplyFilters = (filters: SearchInvoicesFilter) => {
    setInvoicesFilter(filters);
  };

  const handleSortingChange = (property: InvoiceTableSortField, sortDirection: SortDirection) => {
    setSort({ property: property, direction: sortDirection });
  };

  const request: CommonSearchInput = {
    paging: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    sorting: sort && [{ property: sort.property.toString(), direction: sort.direction == 'UP' ? 'ASC' : 'DESC' }],
    searchInputs: [
      searchInput?.length > 0 && {
        properties: ['invoiceNumber', 'ocrNumber', 'agreementNumber'],
        value: searchInput,
        exactMatch: false,
      },
      searchAmountInput && {
        properties: ['totalAmount'],
        value: searchAmountInput,
        exactMatch: true,
      },
    ].filter((search) => search && search.value != ''),
    filters: [
      {
        property: 'paymentDueDate',
        values: invoicesFilter?.paymentDueDate?.fromDate,
        condition: 'GE',
      },
      {
        property: 'paymentDueDate',
        values: invoicesFilter?.paymentDueDate?.toDate,
        condition: 'LE',
      },
      {
        property: 'invoiceStatus',
        values: invoicesFilter?.invoiceStatuses?.length > 0 ? invoicesFilter.invoiceStatuses : invoiceStatuses,
        condition: 'IN',
      },
      {
        property: 'invoiceTypeCode',
        values: invoicesFilter?.invoiceTypes,
        condition: 'IN',
      },
      {
        property: 'insuranceProviderId',
        values: invoicesFilter?.insuranceProviderIds,
        condition: 'IN',
      },
      {
        property: 'employerId',
        values: invoicesFilter?.employerIds,
        condition: 'IN',
      },
    ].filter((filter) => filter.values && filter.values.length > 0),
  };

  return (
    <QueryWrapper
      query={SEARCH_INVOICES}
      loadingComponent={<LoadingPage />}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: request,
        },
      }}
    >
      {({ searchInvoices, paging }: SearchInvoicesPage) => (
        <Invoices
          invoices={searchInvoices}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          sortProperty={sort?.property}
          sortDirection={sort.direction}
          setPageSize={setPageSize}
          searchInvoicesFilter={invoicesFilter}
          searchTextInput={searchInput}
          searchAmountInput={searchAmountInput?.toString()}
          onTextSearch={handleTextSearch}
          onAmountSearch={handleSearchAmount}
          onApplyFilters={handleApplyFilters}
          onApplySorting={handleSortingChange}
          onPageChange={handlePageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default InvoicesTab;

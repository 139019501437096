import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceTableSortField } from './InvoiceTableSortField';
import { TableHeadProps } from 'src/types/TableHeadProps';

interface InvoiceTableHeadsProps {
  sortDirection: SortDirection;
  sortField: InvoiceTableSortField;
  onApplySorting(property: InvoiceTableSortField);
}

const InvoiceTableHeads: FC<InvoiceTableHeadsProps> = ({ sortDirection, sortField, onApplySorting }) => {
  const { t } = useTranslation();

  const tableHeads: TableHeadProps[] = [
    {
      title: t('invoice:table.insurance-provider'),
      fieldName: 'insuranceProvider.legalName',
    },
    {
      title: t('invoice:table.agreement-number'),
      sortDirection,
      fieldName: 'agreementNumber',
      handleSortDirectionChange: () => {
        onApplySorting('agreementNumber');
      },
    },
    {
      title: t('invoice:table.invoice-number'),
      sortDirection,
      fieldName: 'invoiceNumber',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceNumber');
      },
    },
    {
      title: t('invoice:table.invoice-amount'),
      sortDirection,
      fieldName: 'totalAmount',
      handleSortDirectionChange: () => {
        onApplySorting('totalAmount');
      },
    },
    {
      title: t('invoice:table.invoice-remaining-amount'),
      sortDirection,
      fieldName: 'remainingAmount',
      handleSortDirectionChange: () => {
        onApplySorting('remainingAmount');
      },
    },
    {
      title: t('invoice:table.invoice-type-code'),
      sortDirection,
      fieldName: 'invoiceTypeCode',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceTypeCode');
      },
    },
    {
      title: t('invoice:table.ocr-number'),
      sortDirection,
      fieldName: 'ocrNumber',
      handleSortDirectionChange: () => {
        onApplySorting('ocrNumber');
      },
    },
    {
      title: t('invoice:table.due-date'),
      sortDirection,
      fieldName: 'paymentDueDate',
      handleSortDirectionChange: () => {
        onApplySorting('paymentDueDate');
      },
    },
    {
      title: t('invoice:table.invoice-status'),
      fieldName: 'invoiceStatus',
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head, index) => {
        return (
          <Th
            includeSortButtons={head.sortDirection != undefined}
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`invocie-table-head-${index}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default InvoiceTableHeads;
